import * as traversal from '../../utilities/traversal';
import {fadeIn, fadeOut} from '../../utilities/helpers';

export class headNav {

    protected desktopWidth = 1280;
    protected minDesktopWidthQuery = window.matchMedia(`(min-width: ${this.desktopWidth}px)`);

    protected maxTabletLandscapeWidth = 1279;
    protected maxTabletLandscapeWidthQuery = window.matchMedia(`(max-width: ${this.maxTabletLandscapeWidth}px)`);

    protected background : HTMLElement = document.querySelector('.suggestion-background');

    protected defaultSuggestContainer: HTMLElement = document.getElementById('js-default-suggest-container');
    protected suggestContainer: HTMLElement = document.getElementById('js-head-search-suggestion')

    navLvl2HoverOld() {
        let navLvl2: NodeList = document.querySelectorAll('.head-nav-lvl2 > a');
        let navLvl3: NodeList = document.querySelectorAll('.head-nav-lvl3-wrapper');

        navLvl2 = [].slice.call(navLvl2);
        navLvl3 = [].slice.call(navLvl3);

        navLvl2.forEach((item: HTMLElement) => {
            item.addEventListener('mouseenter', () => {
                if (this.minDesktopWidthQuery.matches) {
                    window.setTimeout(() => {
                      if (item.parentElement.classList.contains('hover-init')) {
                        const megaNavBody = document.querySelector('.head-meganav-body');
                        const hover = megaNavBody && megaNavBody.querySelectorAll('._active');
                        hover.forEach((hoverItem) => hoverItem.classList.remove('_active'));
                        item.parentElement.classList.add('_active');
                      }
                    }, 250);
                    item.parentElement.classList.add('hover-init');
                }
            });
            item.addEventListener('mouseleave', () => {
                if (this.minDesktopWidthQuery.matches) {
                    item.parentElement.classList.remove('hover-init');
                }
            });
        });
    }

    toggleMobile() {
        const html = document.getElementsByTagName('html')[0];
        let toggleNav = document.querySelectorAll('.toggle-head-nav');
        let mainNav = document.querySelectorAll('.head-nav-lvl1');
        let toggleIcon = document.querySelectorAll('.toggle-head-nav i');

        toggleNav = [].slice.call(toggleNav);
        mainNav = [].slice.call(mainNav);
        toggleIcon = [].slice.call(toggleIcon);

        toggleNav?.forEach((item: HTMLElement) => {
            item.addEventListener('click', () => {
                event.preventDefault();
                if (toggleNav[0].classList.contains('_active')) {
                    if (typeof mainNav[0] !== 'undefined') {
                        mainNav[0].setAttribute('style', 'display: none;');
                    }
                    if (typeof toggleIcon[0] !== 'undefined') {
                        toggleIcon[0].classList.remove('uil-times')
                        toggleIcon[0].classList.add('uil-bars')
                    }
                    if (typeof toggleNav[0] !== 'undefined') {
                        toggleNav[0].classList.remove('_active');
                    }
                    html.classList.remove('_active-head-nav');
                    document.documentElement.removeAttribute('style');
                } else {
                    if (typeof mainNav[0] !== 'undefined') {
                        if(window.innerWidth < this.maxTabletLandscapeWidth) {
                            const head = document.querySelector('header.head');
                            const topVal = head.getBoundingClientRect().height + 'px';

                            if(head && topVal) {
                                (<HTMLElement>mainNav[0]).style.top = topVal;
                                (<HTMLElement>mainNav[0]).style.height = 'calc(100% - '+topVal+')';
                                setTimeout(() => {
                                    (<HTMLElement>mainNav[0]).scrollTop = 0;
                                }, 10);
                                document.documentElement.style.overflow = 'hidden';
                            }
                        }
                        (<HTMLElement>mainNav[0]).style.display = 'block';
                    }
                    if (typeof toggleIcon[0] !== 'undefined') {
                        toggleIcon[0].classList.remove('uil-bars')
                        toggleIcon[0].classList.add('uil-times')
                    }
                    if (typeof toggleNav[0] !== 'undefined') {
                        toggleNav[0].classList.add('_active');
                    }
                    html.classList.add('_active-head-nav');
                }
            });
        });
    }

    toggleMobileBack() {
        let container           = document.querySelectorAll('.head-meganav')[0];
        let level2Back = document.querySelectorAll('.head-nav-back-lvl2');
        let level3Back = document.querySelectorAll('.head-nav-back-lvl3');
        let level1Navs = document.querySelectorAll('.head-nav-lvl1 > ul > li');
        let level2Navs = document.querySelectorAll('.head-nav-lvl2');
        let level3Navs = document.querySelectorAll('.head-nav-lvl3-wrapper');



        level2Back = [].slice.call(level2Back);
        level3Back = [].slice.call(level3Back);
        level1Navs = [].slice.call(level1Navs);
        level2Navs = [].slice.call(level2Navs);
        level3Navs = [].slice.call(level3Navs);



        if(typeof container !== 'undefined') {
            level2Back.forEach((item: HTMLElement) => {
                item.addEventListener('click', (e) => {
                    if (this.maxTabletLandscapeWidthQuery.matches) {
                        level1Navs.forEach((item: HTMLElement) => {
                            item.classList.remove('_active');
                        });

                        setTimeout(() => {
                            container.setAttribute('style', 'left: 100%;');
                        }, 0);
                    }
                });
            });

            level3Back.forEach((item: HTMLElement) => {
                item.addEventListener('click', (e) => {
                    if (this.maxTabletLandscapeWidthQuery.matches) {
                        let parent = traversal.parents(item, '.head-nav-lvl3-wrapper')[0];

                        parent.classList.remove('_active');
                        level2Navs.forEach((l2nav: HTMLElement) => {
                            l2nav.classList.remove('_active');
                        });

                        setTimeout(() => {
                            parent.setAttribute('style', 'left: 100%;');
                        }, 0);
                    }
                });
            });
        }
    }

    toggle2ndLevel() {
        let level1Nav           = document.querySelectorAll('.head-nav-lvl1')[0];
        let level1Navs          = document.querySelectorAll('.head-nav-lvl1 > ul > li');
        let container           = document.querySelectorAll('.head-meganav')[0];
        level1Navs = [].slice.call(level1Navs);

        // share lots of same code with toggleMegaNav!!!
        if(typeof level1Nav !== 'undefined') {
            let hassub = level1Nav.querySelectorAll('.lvl1-hassub > a');
            let meganavContent = level1Nav.querySelectorAll('.meganav-content');
            let lvl2 = document.querySelectorAll('.head-nav-lvl2');
            let lvl3 = document.querySelectorAll('.head-nav-lvl3-wrapper');


            level1Navs.forEach((item: HTMLElement) => {
                item.addEventListener('click', (e) => {
                    if (this.maxTabletLandscapeWidthQuery.matches) {
                        if (item.classList.contains('lvl1-hassub')) {
                            let navId = item.getAttribute('data-nav-id');
                            let activeLvl2 = level1Nav.querySelectorAll('.meganav-' + navId)[0];

                            // reset active state of previous link
                            hassub.forEach((item: HTMLElement) => {
                                item.classList.remove('_active');
                            });

                            // add active state to selected link
                            item.classList.add('_active');

                            // close previous meganav content
                            meganavContent.forEach((content: HTMLElement) => {
                                content.setAttribute('style', 'display:none;');
                            });

                            // display meganav content
                            lvl2.forEach((item: HTMLElement) => {
                                item.classList.remove('_active');
                            });

                            activeLvl2.setAttribute('style', 'display:block;');
                            if(navId !== '0') {
                                activeLvl2.querySelectorAll('.head-nav-lvl2.hassub > a').forEach((anker: HTMLElement) => {
                                    anker.removeAttribute('href');
                                });
                            }
                            lvl3.forEach((item: HTMLElement) => {
                                // item.setAttribute('style', 'display:none;');
                                item.classList.remove('_active');
                            });

                            setTimeout(() => {
                                container.setAttribute('style', 'left: 0;');
                            }, 0);
                            item.classList.add('_active');
                            e.preventDefault();

                        }
                    }

                });
            });
        }
    }

    toggle2ndLevelOld() {
        let level1Nav           = document.querySelectorAll('.head-nav-lvl1')[0];
        let level1Navs          = document.querySelectorAll('.head-nav-lvl1 > ul > li');
        let container           = document.querySelectorAll('.head-meganav')[0];
        level1Navs = [].slice.call(level1Navs);

        // share lots of same code with toggleMegaNav!!!
        if(typeof level1Nav !== 'undefined') {
            let hassub = level1Nav.querySelectorAll('.lvl1-hassub > a');
            let meganavContent = level1Nav.querySelectorAll('.meganav-content');
            let lvl2 = document.querySelectorAll('.head-nav-lvl2');
            let lvl3 = document.querySelectorAll('.head-nav-lvl3-wrapper');


            level1Navs.forEach((item: HTMLElement) => {
                item.addEventListener('click', (e) => {
                    if (this.maxTabletLandscapeWidthQuery.matches) {
                        if (item.classList.contains('lvl1-hassub')) {
                            let navId = item.getAttribute('data-nav-id');
                            let activeLvl2 = level1Nav.querySelectorAll('.meganav-' + navId)[0];
                            let firstLvl2Item = activeLvl2.querySelectorAll('.head-nav-lvl2')[0];
                            let activeLvl3 = firstLvl2Item.querySelectorAll('.head-nav-lvl3-wrapper')[0];

                            // reset active state of previous link
                            hassub.forEach((item: HTMLElement) => {
                                item.classList.remove('_active');
                            });

                            // add active state to selected link
                            item.classList.add('_active');

                            // close previous meganav content
                            meganavContent.forEach((content: HTMLElement) => {
                                content.setAttribute('style', 'display:none;');
                            });

                            // display meganav content
                            lvl2.forEach((item: HTMLElement) => {
                                item.classList.remove('_active');
                            });

                            activeLvl2.setAttribute('style', 'display:block;');
                            if(navId !== '0') {
                                activeLvl2.querySelectorAll('.head-nav-lvl2.hassub > a').forEach((anker: HTMLElement) => {
                                    anker.removeAttribute('href');
                                });
                            }
                            lvl3.forEach((item: HTMLElement) => {
                                // item.setAttribute('style', 'display:none;');
                                item.classList.remove('_active');
                            });


                            setTimeout(() => {
                                container.setAttribute('style', 'left: 0;');
                            }, 0);
                            item.classList.add('_active');

                            e.preventDefault();

                        }
                    }

                });
            });
        }
    }

    toggle3ndLevel() {
        let level1Nav           = document.querySelectorAll('.head-nav-lvl1')[0];
        let level2Navs          = document.querySelectorAll('.head-nav-lvl2');
        let level3Navs          = document.querySelectorAll('.head-nav-lvl3-wrapper');
        level3Navs      = [].slice.call(level3Navs);
        level2Navs      = [].slice.call(level2Navs);

        // share lots of same code with toggleMegaNav!!!
        if(typeof level1Nav !== 'undefined') {
            level2Navs.forEach((item: HTMLElement) => {
                if(item.querySelector('a')) {
                    let link = item.querySelectorAll('a')[0];
                    link.addEventListener('click', (e) => {
                        if (this.maxTabletLandscapeWidthQuery.matches) {
                            if (item.classList.contains('hassub')) {
                                const activeLvl3 = item.querySelector('.head-nav-lvl3-wrapper');
                                // reset active state of previous link
                                level2Navs.forEach((item2: HTMLElement) => {
                                    item2.classList.remove('_active');
                                });
                                // reset active state of previous link
                                level3Navs.forEach((item3: HTMLElement) => {
                                    item3.classList.remove('_active');
                                });

                                // add active state to selected link
                                item.classList.add('_active');
                                activeLvl3.classList.add('_active');

                                setTimeout(() => {
                                    activeLvl3.setAttribute('style', 'left: 0;');
                                }, 0);
                                e.preventDefault();
                            }
                        }
                    });
                }
            });
        }
    }

    toggle3ndLevelOld() {
        let level1Nav           = document.querySelectorAll('.head-nav-lvl1')[0];
        let level2Navs          = document.querySelectorAll('.head-nav-lvl2');
        let level3Navs          = document.querySelectorAll('.head-nav-lvl3-wrapper');
        level3Navs      = [].slice.call(level3Navs);
        level2Navs      = [].slice.call(level2Navs);

        // share lots of same code with toggleMegaNav!!!
        if(typeof level1Nav !== 'undefined') {
            level2Navs.forEach((item: HTMLElement) => {
                if(item.querySelector('a')) {
                    let link           = item.querySelectorAll('a')[0];
                    link.addEventListener('click', (e) => {
                        if (this.maxTabletLandscapeWidthQuery.matches) {
                            if (item.classList.contains('hassub')) {
                                const activeLvl3 = item.querySelector('.head-nav-lvl3-wrapper');
                                // reset active state of previous link
                                level2Navs.forEach((item2: HTMLElement) => {
                                    item2.classList.remove('_active');
                                });
                                // reset active state of previous link
                                level3Navs.forEach((item3: HTMLElement) => {
                                    item3.classList.remove('_active');
                                });

                                // add active state to selected link
                                item.classList.add('_active');
                                activeLvl3.classList.add('_active');

                                setTimeout(() => {
                                    activeLvl3.setAttribute('style', 'left: 0;');
                                }, 0);
                                e.preventDefault();
                            }
                        }
                    });
                }
            });
        }
    }

    toggleMegaNav() {
        let container           = document.querySelectorAll('.head-meganav')[0];
        let level1Nav           = document.querySelectorAll('.head-nav-lvl1')[0];
        let meganavContent: NodeListOf<HTMLElement>;
        let lvl2: NodeListOf<HTMLElement>;
        let lvl3: NodeListOf<HTMLElement>;
        let timeout: number;

        // continue if menu exist
        if(typeof level1Nav !== 'undefined') {
            let hassub = level1Nav.querySelectorAll('.lvl1-hassub');
            meganavContent = level1Nav.querySelectorAll('.meganav-content');
            let nosub = level1Nav.querySelectorAll('.lvl1-nosub');
            lvl2 = document.querySelectorAll('.head-nav-lvl2');
            lvl3 = document.querySelectorAll('.head-nav-lvl3-wrapper');
            let delay: number;
            hassub = [].slice.call(hassub);
            meganavContent = [].slice.call(meganavContent);
            nosub = [].slice.call(nosub);
            lvl2 = [].slice.call(lvl2);
            lvl3 = [].slice.call(lvl3);

            // close & reset meganav onleave
            level1Nav.addEventListener('mouseleave', () => {
                if (this.minDesktopWidthQuery.matches) {
                    fadeOut(this.background);
                    container.removeAttribute('style');
                    container.classList.remove('_active');
                    container.classList.remove('_in');
                    (document.querySelector('.head-nav-lvl1') as HTMLElement).removeAttribute('style');

                    clearTimeout(timeout);
                    clearTimeout(delay);

                    hassub.forEach((item: HTMLElement) => {
                        item.classList.remove('_active');
                    });
                }
            });

            // close & reset meganav if hovering elements without sub
            nosub.forEach((item: HTMLElement) => {
                item.addEventListener('mouseover', () => {
                    if (this.minDesktopWidthQuery.matches) {
                        fadeOut(this.background);
                        container.removeAttribute('style');
                        container.classList.remove('_active');
                        container.classList.remove('_in');
                        (document.querySelector('.head-nav-lvl1') as HTMLElement).removeAttribute('style');

                        clearTimeout(timeout);
                        clearTimeout(delay);

                        hassub.forEach((item: HTMLElement) => {
                            item.classList.remove('_active');
                        });
                    }
                });
            });

            // open meganav
            hassub.forEach((item: HTMLElement) => {
                item.addEventListener('mouseover', () => {
                    if (this.minDesktopWidthQuery.matches) {
                        let navId = item.getAttribute('data-nav-id');
                        let activeLvl2 = level1Nav.querySelectorAll('.meganav-' + navId)[0];

                        clearTimeout(delay);

                          this.background.style.display = 'block';
                          setTimeout(() => {
                            this.background.style.opacity = '1';
                            this.background.style.transitionDelay = '.3s';
                          }, 10);

                        if (!container.classList.contains('_active')) {
                          toggleNavItems(activeLvl2);
                          item.classList.add('_active');
                        } else {
                            delay = window.setTimeout(() => {
                              // reset active state of previous link
                              hassub.forEach((item: HTMLElement) => {
                                  item.classList.remove('_active');
                              });
                              item.classList.add('_active');
                              toggleNavItems(activeLvl2);
                            }, 350);
                        }
                    }
                });
            });
        }

        function toggleNavItems(activeLvl2: Element) {
            // close previous meganav content

            meganavContent.forEach((content: HTMLElement) => {
                content.setAttribute('style', 'display:none;');
            });

            // display meganav content
            lvl2.forEach((item: HTMLElement) => {
                item.classList.remove('_active');
            });
            activeLvl2.setAttribute('style', 'display:flex;');
            container.scrollTop = 0;
            lvl3.forEach((item: HTMLElement) => {
                item.classList.remove('_active');
            });

            (document.querySelector('.head-nav-lvl1') as HTMLElement).style.setProperty('display', 'block', 'important');

            let offsetHeight = (document.querySelector('.head-wrapper') as HTMLElement).offsetHeight + 24 + 'px';
            offsetHeight = 'calc(100vh - ' + offsetHeight + ')';
            // if meganav not already active, slide down
            if (!container.classList.contains('_active')) {
                container.classList.add('_active');
                timeout = window.setTimeout(() => {
                    container.classList.add('_in');
                }, 250);
            }
            setTimeout(() => {
                (<HTMLElement>container).style.maxHeight = offsetHeight;
            }, 0);
        }
    }

    toggleMegaNavOld() {
        let container           = document.querySelectorAll('.head-meganav')[0];
        let level1Nav           = document.querySelectorAll('.head-nav-lvl1')[0];
        let meganavContent: NodeListOf<HTMLElement>;
        let lvl2: NodeListOf<HTMLElement>;
        let lvl3: NodeListOf<HTMLElement>;
        let timeout: number;

        // continue if menu exist
        if(typeof level1Nav !== 'undefined') {
            let hassub = level1Nav.querySelectorAll('.lvl1-hassub');
            meganavContent = level1Nav.querySelectorAll('.meganav-content');
            let nosub = level1Nav.querySelectorAll('.lvl1-nosub');
            lvl2 = document.querySelectorAll('.head-nav-lvl2');
            lvl3 = document.querySelectorAll('.head-nav-lvl3-wrapper');
            let delay: number;
            hassub = [].slice.call(hassub);
            meganavContent = [].slice.call(meganavContent);
            nosub = [].slice.call(nosub);
            lvl2 = [].slice.call(lvl2);
            lvl3 = [].slice.call(lvl3);

            // close & reset meganav onleave
            level1Nav.addEventListener('mouseleave', () => {
                if (this.minDesktopWidthQuery.matches) {
                    container.setAttribute('style', 'height:0px;');
                    container.classList.remove('_active');
                    container.classList.remove('_in');

                    clearTimeout(timeout);
                    clearTimeout(delay);

                    hassub.forEach((item: HTMLElement) => {
                        item.classList.remove('_active');
                    });
                }
            });

            // close & reset meganav if hovering elements without sub
            nosub.forEach((item: HTMLElement) => {
                item.addEventListener('mouseover', () => {
                    if (this.minDesktopWidthQuery.matches) {
                        container.setAttribute('style', 'height:0px;');
                        container.classList.remove('_active');
                        container.classList.remove('_in');

                        clearTimeout(timeout);
                        clearTimeout(delay);

                        hassub.forEach((item: HTMLElement) => {
                            item.classList.remove('_active');
                        });
                    }
                });
            });

            // open meganav
            hassub.forEach((item: HTMLElement) => {
                item.addEventListener('mouseover', () => {
                    if (this.minDesktopWidthQuery.matches) {
                        let navId = item.getAttribute('data-nav-id');
                        let activeLvl2 = level1Nav.querySelectorAll('.meganav-' + navId)[0];
                        let firstLvl2Item = activeLvl2.querySelectorAll('.head-nav-lvl2')[0];
                        let activeLvl3 = firstLvl2Item.querySelectorAll('.head-nav-lvl3-wrapper')[0];

                        clearTimeout(delay);

                        if (!container.classList.contains('_active')) {
                          toggleNavItems(firstLvl2Item, activeLvl2, activeLvl3);
                          item.classList.add('_active');
                        } else {
                            delay = window.setTimeout(() => {
                              // reset active state of previous link
                              hassub.forEach((item: HTMLElement) => {
                                  item.classList.remove('_active');
                              });
                              item.classList.add('_active');
                              toggleNavItems(firstLvl2Item, activeLvl2, activeLvl3);
                            }, 400);
                        }
                    }
                });
                item.addEventListener('mouseleave', () => {
                    clearTimeout(delay);
                });
            });
        }

        function toggleNavItems(firstLvl2Item: Element, activeLvl2: Element, activeLvl3: Element) {
            // close previous meganav content
            meganavContent.forEach((content: HTMLElement) => {
                content.setAttribute('style', 'display:none;');
            });

            // display meganav content
            lvl2.forEach((item: HTMLElement) => {
                item.classList.remove('_active');
            });
            firstLvl2Item.classList.add('_active');
            activeLvl2.setAttribute('style', 'display:block;');
            lvl3.forEach((item: HTMLElement) => {
                item.classList.remove('_active');
            });
            if (typeof activeLvl3 !== 'undefined') {
                activeLvl3.classList.add('_active');
            }


            // if meganav not already active, slide down
            if (!container.classList.contains('_active')) {
                container.classList.add('_active');
                var height = (<HTMLElement>document.querySelector('.head-meganav-body')).offsetHeight;
                height += parseInt(window.getComputedStyle((<HTMLElement>document.querySelector('.head-meganav-body'))).getPropertyValue('margin-top'));
                height += parseInt(window.getComputedStyle((<HTMLElement>document.querySelector('.head-meganav-body'))).getPropertyValue('margin-bottom'));
                setTimeout(() => {
                    (<HTMLElement>container).style.height = height + 'px';
                }, 0);
                timeout = window.setTimeout(() => {
                    container.classList.add('_in');
                }, 250);
            } else {
                    var height = (<HTMLElement>document.querySelector('.head-meganav-body')).offsetHeight;
                    height += parseInt(window.getComputedStyle((<HTMLElement>document.querySelector('.head-meganav-body'))).getPropertyValue('margin-top'));
                    height += parseInt(window.getComputedStyle((<HTMLElement>document.querySelector('.head-meganav-body'))).getPropertyValue('margin-bottom'));

                setTimeout(() => {
                    (<HTMLElement>container).style.height = height + 'px';
                }, 0);
            }
        }
    }

    stateReset() {
        const html = document.getElementsByTagName('html')[0];
        let level1Nav = document.querySelectorAll('.head-nav-lvl1');
        level1Nav = [].slice.call(level1Nav);

        if(typeof level1Nav !== 'undefined' && level1Nav.length > 0) {
            let toggleNav = document.querySelectorAll('.toggle-head-nav');
            let toggleIcon = document.querySelectorAll('.toggle-head-nav i');
            toggleNav = [].slice.call(toggleNav);
            toggleIcon = [].slice.call(toggleIcon);

            window.addEventListener('resize', () => {
                if (this.maxTabletLandscapeWidthQuery.matches) {
                    level1Nav[0].setAttribute('style', 'display: none;');
                    toggleIcon[0].classList.remove('uil-times')
                    toggleIcon[0].classList.add('uil-bars')
                    toggleNav[0].classList.remove('_active');
                } else {
                    level1Nav[0].setAttribute('style', '');
                    html.classList.remove('_active-head-nav');
                }
            });
        }
    }

    init() {
        if(document.querySelector('.head-wrapper')) {
            this.toggleMobile();
            this.toggleMobileBack();
            this.stateReset();
            if (document.querySelector('.head-wrapper').classList.contains('oldnav')) {
                this.navLvl2HoverOld();
                this.toggle2ndLevelOld();
                this.toggle3ndLevelOld();
                this.toggleMegaNavOld();
            } else {
                this.toggle2ndLevel();
                this.toggle3ndLevel();
                this.toggleMegaNav();
            }
        }
    }
}
